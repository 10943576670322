/* cookie.js handling cookies */
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}

const GDPR_CONSENT_VERSION = window.GDPR_CONSENT_VERSION || '1';

// Generate a Globally Unique IDentifier
function guid() {
  function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
}

// String trim polyfill
if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  };
}

var CONSENT_COOKIE_NAME = 'consent_cookie';
var GDPR_COOKIE_NAME = 'duunitori_gdpr_terms_accepted';

/**
 * Valid keys for consent cookie to have. Not ideal, but without TypeScript this will have to do.
 */
var consentCookieKeys = {
  required: 'required',
  functional: 'functional',
  analytics: 'analytics',
  advertising: 'advertising',
};

/**
 * If user doesn't have the consent cookie stored yet, this is the initial value.
 */
var initialConsentCookie = {
  required: true,
  functional: false,
  analytics: false,
  advertising: false,
  hash: '',
  version: '',
};

/**
 * Check if user has given consent to a given category. Returns false if category is invalid.
 *
 * @param {string} c_category
 * @returns {boolean} Boolean indicating whether cookie can be saved or not.
 */
function checkCookieConsentFor(c_category) {
  if (c_category === consentCookieKeys.required) {
    return true;
  }

  var consentCookie = getCookie(CONSENT_COOKIE_NAME);
  if (!consentCookie) {
    return false;
  }

  var consentJSON = {};

  try {
    consentJSON = JSON.parse(window.atob(consentCookie));
  } catch (e) {
    return false;
  }

  var consent = consentJSON[c_category];

  if (typeof consent !== "boolean") {
    return false;
  }

  return consent;
}

/**
 * Check if user has chosen what cookies to store.
 */
function hasCookieConsent() {
  var consentCookie = getCookie(GDPR_COOKIE_NAME);
  if (consentCookie === null) {
    return false;
  }

  return consentCookie;
}

/**
 * Sets cookie that expires in x days. If cookie category is not defined, doesn't set the cookie.
 *
 * @param {string} c_name
 * @param {string} value
 * @param {number} exdays
 * @param {'required' | 'functional' | 'analytics' | 'advertising'} cookieCategory
 * @param {boolean} showFunctionalConsentMissingError
 *
 * @returns {string | null} Set cookie or null if cookie was not set.
 */
function setCookie(c_name, value, exdays, cookieCategory, showFunctionalConsentMissingError) {
  if (!cookieCategory) {
    return null;
  }

  if (!checkCookieConsentFor(cookieCategory)) {
    if (cookieCategory === consentCookieKeys.functional && showFunctionalConsentMissingError) {
      // TODO(Jerry): Make a pretty element to display with link to change cookie settings.
      alert('Tämä ominaisuus vaatii toiminnallisia evästeitä toimiakseen oikein.')
    }
    return null;
  }

  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value = escape(value) + ((exdays === null) ? "" : "; expires=" + exdate.toUTCString()) + "; path=/";

  var fullCookie = c_name + "=" + c_value;

  document.cookie = fullCookie;

  return fullCookie;
}

/**
 * Sets cookie that expires in x milliseconds. If cookie category is not defined, doesn't set the cookie.
 *
 * @param {string} c_name
 * @param {string} value
 * @param {number} ms
 * @param {'required' | 'functional' | 'analytics' | 'advertising'} cookieCategory
 *
 * @returns {string | null} Set cookie or null if cookie was not set.
 */
function setCookieByMilliseconds(c_name, value, ms, cookieCategory) {
  if (!cookieCategory) {
    return null;
  }

  if (!checkCookieConsentFor(cookieCategory)) {
    return null;
  }

  // Add milliseconds to current date
  var date = new Date();
  var exDate = new Date(date.getTime() + ms);

  // "cookie_value; expires=DateString; path=/"
  var c_value = escape(value) + "; expires=" + exDate.toUTCString() + "; path=/";

  var fullCookie = c_name + "=" + c_value;

  // Save cookie
  document.cookie = fullCookie;

  return fullCookie;
}

/**
 * Delete cookie. Duh.
 *
 * @param {string} c_name
 */
function deleteCookie(c_name) {
  if (!getCookie(c_name)) {
    return;
  }

  setCookie(c_name, '', -1, 'required');
}

/**
 *
 * @param {string} name
 * @returns {string | null} Cookie value or null, if cookie was not found.
 */
function getCookie(name) {
  var cookieValue = null;

  if (name === undefined) {
    return null;
  }

  if (!document.cookie || document.cookie === '') {
    return null;
  }

  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();

    // Does this cookie string begin with the name we want?
    if (cookie.substring(0, name.length + 1) === (name + '=')) {
      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      break;
    }
  }

  return cookieValue;
}

/**
 * Returns consent cookie as JSON, or null, if parsing to JSON failed.
 *
 * @returns {initialConsentCookie | null}
 */
function getConsentCookieAsJSON() {
  var cookie = getCookie(CONSENT_COOKIE_NAME);
  if (cookie === null) {
    return null;
  }
  var cookieStr = atob(cookie);

  try {
    return JSON.parse(cookieStr);
  } catch (e) {
    return null;
  }
}

/**
 * Check if cookie with name exists.
 *
 * @param {string} name
 */
function isCookieSet(name){
  var cookie = getCookie(name);
  if (cookie !== null && cookie !== "") {
    return true;
  }
  return false;
}

/**
 *
 * @param {initialConsentCookie} consentCookie
 * @param {boolean} saveConsent
 *
 * @returns {string | null} Set cookie or null if cookie was not set.
 */
function setConsentCookie(consentCookie, saveConsent = true) {
  // Add a random hash if one does not exist
  const current = getConsentCookieAsJSON();
  var hash = guid();
  if (current !== null && current.hash) {
    hash = current.hash;
  }

  consentCookie.hash = hash;

  // Add version number
  consentCookie.version = GDPR_CONSENT_VERSION;

  consentCookie.updated_at = new Date();

  if (saveConsent) {
    saveConsentMode(consentCookie);
  }

  fetch('/cookie-consent', {
    method: 'POST',
    body: JSON.stringify(consentCookie),
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
    }
  }).catch(() => {});

  return setCookie(CONSENT_COOKIE_NAME, window.btoa(JSON.stringify(consentCookie)), 365, consentCookieKeys.required)
}

/**
 *
 * @param {initialConsentCookie} consentCookie
 */

function pushConsentToDataLayer(consentCookie, type = 'update') {
  window.dataLayer.push({
    event: 'GDPRConsent',
    consentType: type,
    consentRequired: consentCookie.required,
    consentFunctional: consentCookie.functional,
    consentAnalytics: consentCookie.analytics,
    consentAdvertising: consentCookie.advertising,
  })
}

/**
 *
 * @param {initialConsentCookie} consentCookie
 */

function saveConsentMode(consentCookie, type = 'update') {
  gtag('consent', type, {
    'ad_storage': consentCookie.advertising ? 'granted' : 'denied',
    'ad_user_data': consentCookie.advertising ? 'granted' : 'denied',
    'ad_personalization': consentCookie.advertising ? 'granted' : 'denied',
    'analytics_storage': 'granted',
    'functionality_storage': consentCookie.functional ? 'granted' : 'denied'
  })

  pushConsentToDataLayer(consentCookie, type);
}

/**
 * @returns {string | null} Set cookie or null if cookie was not set.
 */
function acceptAllCookies() {
  var consentCookie = {
    required: true,
    functional: true,
    analytics: true,
    advertising: true,
  };

  return setConsentCookie(consentCookie);
}

/**
 * Initialize cookies.
 */
(function () {
  var consentCookie = getConsentCookieAsJSON();

  // Always initialize consent mode to denied, no matter if we know what choices user has made.
  // This is required behavior by Google
  saveConsentMode(initialConsentCookie, 'default');

  if (consentCookie) {
    // Update consent mode with existing choices
    saveConsentMode(getConsentCookieAsJSON());
  } else {
    // Set initial cookies
    setConsentCookie(initialConsentCookie, false);

    if (isCookieSet(GDPR_COOKIE_NAME)) {
      // Invalidate gdpr cookie for returning users.
      deleteCookie(GDPR_COOKIE_NAME);
    }
  }

  if (!isCookieSet(GDPR_COOKIE_NAME)) {
    setCookie(GDPR_COOKIE_NAME, false, 365, "required");
  }
})();
